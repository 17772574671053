@-webkit-keyframes hvr-back-pulse {
  50% {
    background-color: rgba(32, 152, 209, 0.75);
  }
}
@keyframes hvr-back-pulse {
  50% {
    background-color: rgba(32, 152, 209, 0.75);
  }
}
.hvr-back-pulse {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  overflow: hidden;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
.hvr-back-pulse:hover, .hvr-back-pulse:focus, .hvr-back-pulse:active {
  -webkit-animation-name: hvr-back-pulse;
  animation-name: hvr-back-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-color: #2098D1;
  background-color: #2098d1;
  color: white;
}

.category-card {
  min-width: 30%;
  height: 80vh;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 15px;
  margin: 0 7.5px 15px;
  background-position: center;
  background-size: contain;
  background-color: none;
  cursor: pointer;
  

  &:first-child {
    margin-right: 7.5px;
  }
  &:last-child {
    margin-left: 7.5px;
  }

  .background-image{
    height: 100%;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }

  .content {
    height: 90px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    // border: 1px solid black;
    // background-color: rgba($color: #ffffff, $alpha: .4);

    .title {
      font-weight: bold;
      margin-bottom: 0px;
      font-size: 22px;
      color: white;
    }
    .subtitle {
      font-weight: lighter;
      font-size: 16px;
    }
  }
}
