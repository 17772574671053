*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  color: white;
}

.Particles {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: royalblue;
}



.moveImgContainer {
  width: 100%;
}

.moveImgContainer img {
  object-fit: contain;
  max-width: 100%;
  height: auto;
}

.background-img{
  background-image: url(https://firebasestorage.googleapis.com/v0/b/waterstone-98dcd.appspot.com/o/website_assets%2Fimages%2FWaterstone%20Marble%20Backdrop-min.jpg?alt=media&token=142926c8-262e-4faa-88cd-e0e5bb7e3b18);
  height: 100vh;
  background-size: contain;
  position: absolute;
  z-index: -1;
  background-attachment: fixed; 
}