.main {
  text-align: center;
  padding: 0 10%;
  h1{
    font-size: 4.5vw;
  }
  p{
    font-size: 2vw;
  }
  .move-info-panel{
  }
}

@media screen and (max-width: 600px) {
  .main h1 {
    font-size: 2rem;
  }
  //TODO get this actually working
}


.scroll-container{
  overflow: scroll;
  max-height: 50vh;
  overflow-x: hidden;
}

.move-info-container {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 300px;
  }
  &:hover .player-overlay-text{
    visibility: visible;
  }
}

.player-overlay-text {
  position: absolute;
  width: fit-content;
  z-index: 10;
  top: 200px;
  left: 16px;
  visibility: hidden;
}



.media-container {
  position: relative;
  z-index: 0;
  min-width: 70%;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
