@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
body {
  background-color: #2b2b2b;
  color: #FFF;
  font-family: 'Roboto', sans-serif;
}

.container {
  max-width: 500px;
  margin: 0px auto;
}
.container h1 {
  text-align: center;
}
.container .progressbar-container {
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid #FFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.container .progressbar-container .progressbar-complete {
  position: absolute;
  left: 0;
  top: 0px;
  height: 100%;
  background-color: #5225bd;
  border-radius: 10px;
  animation: g 2500ms infinite ease-in-out;
  z-index: 2;
  transition:1000ms linear;
}

.container .progressbar-container .progressbar-complete .progressbar-liquid {
  z-index: 1;
  width: 70px;
  height: 70px;
  animation: g 2500ms infinite ease-in-out, r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
  position: absolute;
  right: -5px;
  top: -10px;
  background-color: #5225bd;
  border-radius: 40%;
}

.text-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  z-index: 2;
}

.move-name{
  text-align: left;
}

.move-time{
  text-align: right;
}

/* 
Possible code to use to pause the animation in the middle of it adjusting itself (maybe javascript is required)
.paused{
  -webkit-animation-play-state:paused;
  -moz-animation-play-state:paused;
  -o-animation-play-state:paused; 
  animation-play-state:paused;
} */


@keyframes g {
  0% {
    background-color: #5225bd;
  }
  50% {
    background-color: #607e9e;
  }
  100% {
    background-color: #5225bd;
  }
}
@keyframes r {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
