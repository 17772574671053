.video-container {
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .play-btn {
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 4rem;
  }
}


