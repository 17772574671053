.workout-constructor-form {
  flex-direction: column;
  display: flex;
  align-items: center;

  //styles for both containers
  & div {
    max-width: 650px;
    width: 100%;
    background-color: #222222;

    padding: 20px;
  }
  .workout-form-card {
    border-radius: 10px;
    button{
      margin: 0 10px;
      padding: 5px;
      border-radius: 5px;
      border: none;
    }
    .clear-btn{
      background-color:  #cc1111;
      color: white;
    }
    .submit-btn {
      margin: 0 10px;
      padding: 5px;
      border-radius: 5px;
      border: none;
      color: white;
      background-color: #1344ae;
    }
    .submit-btn:disabled{
      color: grey;
      background-color: #395aa0;
    }
    .workout-structure {
      .section-container {
        margin: 20px 0;

        .option-btn {
          margin: 0 10px;
          padding: 5px;
          border-radius: 5px;
          border: none;
          &.delete-btn {
            color: white;
            background-color: #ae1313;
          }
        }
      }
    }
  }
}
.videoList-container {
  display: flex;
  flex-direction: column;
}
