.move-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid white;
  border-radius: 5px;
  margin: 10px auto;
  height: 50px;
  background-color: rgba(33, 33, 33, 33);
  padding: 0 1rem;
}

.move-item:hover{
  background-color: rgba(45, 45, 45, 45);
}

.move-list{
  height: 300px;
  /* overflow: auto; */
  overflow-y: scroll;
  overflow-x:hidden;
  
}

/* Hide scrollbar for Chrome, Safari and Opera */
.move-list::-webkit-scrollbar {
  display: none;
}

.current-move {
  background-color: rgba(90, 90, 90, 30);
}

.move-text{
  font-size: 1.2em;
  cursor: pointer;
}

.move-info-btn{
  cursor: pointer;
}