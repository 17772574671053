.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 20px;

  .logo-container {
    height: 100%;
    width: 70px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  a:hover{
    color: hsl(195, 92%, 55%);
  }

  .options {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .option {
      cursor: pointer;
      margin-left: 10px;
    }
  }
}
