// palette
$starting: #3a4ad3;
$light-ending: #ffecd9;
$ending: #88c7e0;
$transition: #b5c5fc;
$white: #fff;
$google: #DB4437;
$google-end: rgb(243, 130, 119);

// body{
//   background: $light-peach
//   display: grid
//   height: 100vh
//   margin: 0
//   place-items: center
//   padding: 1rem
// }

.button {
  &.google{
    background: -webkit-gradient(to right, $google 0%, $light-ending 50%, $google-end 100%);
    background: linear-gradient(to right, $google 0%, $light-ending 50%, $google-end 100%);
    background-size: 500%;
  }
  -webkit-appearance: none;
  background: -webkit-gradient(to right, $starting 0%, $transition 50%, $ending 100%);
  background: linear-gradient(to right, $starting 0%, $transition 50%, $ending 100%);
  background-size: 500%;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  color: $white;
  cursor: pointer;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 3rem;
  letter-spacing: 0.05em;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  margin-top: 10px;
  
  animation-duration: 0.7s;
  -webkit-animation-duration: 0.7s;
  transition: all 0.5s ease-out;
  
  &:active{
    opacity: .9;
  }

  &:hover {
    transition: .2s transform,.2s color,.2s -webkit-transform;
    transform: translateY(-2px); 
    animation-name: gradient;
    -webkit-animation-name: gradient;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    
  }

  
  
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  };
  100% {
    background-position: 100%;
  };
}
