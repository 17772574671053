@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: 'Assistant', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to top, #43434323 0%, rgba(0, 0, 0, 0.35) 100%);
  background-attachment: fixed;
  background-size: cover;
  color: white;
  background-attachment: fixed;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/waterstone-98dcd.appspot.com/o/website_assets%2Fimages%2FWaterstone%20Marble%20Backdrop-min-rotated.jpg?alt=media&token=6467d562-4db7-4996-8fdb-6faeb357a624);
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.move-list-container{margin-top:20px}

.move-item{display:flex;align-items:center;justify-content:space-between;border:1px solid white;border-radius:5px;margin:10px auto;height:50px;background-color:#212121;padding:0 1rem}.move-item:hover{background-color:#2d2d2d}.move-list{height:300px;overflow-y:scroll;overflow-x:hidden}.move-list::-webkit-scrollbar{display:none}.current-move{background-color:#5a5a5a}.move-text{font-size:1.2em;cursor:pointer}.move-info-btn{cursor:pointer}

body {
  background-color: #2b2b2b;
  color: #FFF;
  font-family: 'Roboto', sans-serif;
}

.container {
  max-width: 500px;
  margin: 0px auto;
}
.container h1 {
  text-align: center;
}
.container .progressbar-container {
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid #FFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.container .progressbar-container .progressbar-complete {
  position: absolute;
  left: 0;
  top: 0px;
  height: 100%;
  background-color: #5225bd;
  border-radius: 10px;
  -webkit-animation: g 2500ms infinite ease-in-out;
          animation: g 2500ms infinite ease-in-out;
  z-index: 2;
  transition:1000ms linear;
}

.container .progressbar-container .progressbar-complete .progressbar-liquid {
  z-index: 1;
  width: 70px;
  height: 70px;
  -webkit-animation: g 2500ms infinite ease-in-out, r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
          animation: g 2500ms infinite ease-in-out, r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
  position: absolute;
  right: -5px;
  top: -10px;
  background-color: #5225bd;
  border-radius: 40%;
}

.text-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  z-index: 2;
}

.move-name{
  text-align: left;
}

.move-time{
  text-align: right;
}

/* 
Possible code to use to pause the animation in the middle of it adjusting itself (maybe javascript is required)
.paused{
  -webkit-animation-play-state:paused;
  -moz-animation-play-state:paused;
  -o-animation-play-state:paused; 
  animation-play-state:paused;
} */


@-webkit-keyframes g {
  0% {
    background-color: #5225bd;
  }
  50% {
    background-color: #607e9e;
  }
  100% {
    background-color: #5225bd;
  }
}


@keyframes g {
  0% {
    background-color: #5225bd;
  }
  50% {
    background-color: #607e9e;
  }
  100% {
    background-color: #5225bd;
  }
}
@-webkit-keyframes r {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
@keyframes r {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}

.button{-webkit-appearance:none;background:-webkit-gradient(to right, #3a4ad3 0%, #b5c5fc 50%, #88c7e0 100%);background:linear-gradient(to right, #3a4ad3 0%, #b5c5fc 50%, #88c7e0 100%);background-size:500%;border:none;border-radius:5px;box-shadow:0 0.5rem 1rem rgba(0,0,0,0.15);color:#fff;cursor:pointer;font-size:1rem;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;height:3rem;letter-spacing:0.05em;outline:none;-webkit-tap-highlight-color:transparent;-webkit-user-select:none;-ms-user-select:none;user-select:none;width:100%;margin-top:10px;animation-duration:0.7s;-webkit-animation-duration:0.7s;transition:all 0.5s ease-out}.button.google{background:-webkit-gradient(to right, #DB4437 0%, #ffecd9 50%, #f38277 100%);background:linear-gradient(to right, #DB4437 0%, #ffecd9 50%, #f38277 100%);background-size:500%}.button:active{opacity:.9}.button:hover{transition:.2s transform,.2s color,.2s -webkit-transform;transform:translateY(-2px);animation-name:gradient;-webkit-animation-name:gradient;animation-iteration-count:1;-webkit-animation-iteration-count:1;animation-fill-mode:forwards;-webkit-animation-fill-mode:forwards}@-webkit-keyframes gradient{0%{background-position:0% 50%}100%{background-position:100%}}@keyframes gradient{0%{background-position:0% 50%}100%{background-position:100%}}

.video-container{padding-bottom:56.25%;height:0;overflow:hidden}.video-container iframe,.video-container object,.video-container embed{position:absolute;top:0;left:0;width:100%;height:100%}.video-container .play-btn{z-index:1;position:absolute;left:0;right:0;margin:0 auto;transform:translateY(-50%);font-size:4rem}


.customInput-label{display:flex;flex-direction:column;align-items:flex-start}.customInput-label .customInput-input{margin:5px 0px 10px 0;font-size:1rem;width:100%}

.main{text-align:center;padding:0 10%}.main h1{font-size:4.5vw}.main p{font-size:2vw}@media screen and (max-width: 600px){.main h1{font-size:2rem}}.scroll-container{overflow:scroll;max-height:50vh;overflow-x:hidden}.move-info-container{position:relative;z-index:1;display:flex;justify-content:center}.move-info-container img{object-fit:contain;max-width:100%;max-height:300px}.move-info-container:hover .player-overlay-text{visibility:visible}.player-overlay-text{position:absolute;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;z-index:10;top:200px;left:16px;visibility:hidden}.media-container{position:relative;z-index:0;min-width:70%;display:flex;justify-content:center;margin:1rem 0}

.workout-constructor-form{flex-direction:column;display:flex;align-items:center}.workout-constructor-form div{max-width:650px;width:100%;background-color:#222222;padding:20px}.workout-constructor-form .workout-form-card{border-radius:10px}.workout-constructor-form .workout-form-card button{margin:0 10px;padding:5px;border-radius:5px;border:none}.workout-constructor-form .workout-form-card .clear-btn{background-color:#cc1111;color:white}.workout-constructor-form .workout-form-card .submit-btn{margin:0 10px;padding:5px;border-radius:5px;border:none;color:white;background-color:#1344ae}.workout-constructor-form .workout-form-card .submit-btn:disabled{color:grey;background-color:#395aa0}.workout-constructor-form .workout-form-card .workout-structure .section-container{margin:20px 0}.workout-constructor-form .workout-form-card .workout-structure .section-container .option-btn{margin:0 10px;padding:5px;border-radius:5px;border:none}.workout-constructor-form .workout-form-card .workout-structure .section-container .option-btn.delete-btn{color:white;background-color:#ae1313}.videoList-container{display:flex;flex-direction:column}


.header{display:flex;align-items:center;justify-content:space-between;margin:10px 20px}.header .logo-container{height:100%;width:70px}.header .logo-container img{width:100%;height:100%}.header a:hover{color:#23c1f6}.header .options{width:50%;height:100%;display:flex;align-items:center;justify-content:flex-end}.header .options .option{cursor:pointer;margin-left:10px}

.categories-menu{width:100%;display:flex;flex-wrap:wrap;justify-content:space-around}

@-webkit-keyframes hvr-back-pulse{50%{background-color:rgba(32,152,209,0.75)}}@keyframes hvr-back-pulse{50%{background-color:rgba(32,152,209,0.75)}}.hvr-back-pulse{display:inline-block;vertical-align:middle;transform:perspective(1px) translateZ(0);box-shadow:0 0 1px rgba(0,0,0,0);overflow:hidden;transition-duration:0.5s;transition-property:color, background-color}.hvr-back-pulse:hover,.hvr-back-pulse:focus,.hvr-back-pulse:active{-webkit-animation-name:hvr-back-pulse;animation-name:hvr-back-pulse;-webkit-animation-duration:1s;animation-duration:1s;-webkit-animation-delay:0.5s;animation-delay:0.5s;-webkit-animation-timing-function:linear;animation-timing-function:linear;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;background-color:#2098D1;background-color:#2098d1;color:white}.category-card{min-width:30%;height:80vh;display:flex;align-items:flex-end;justify-content:space-between;border:1px solid black;border-radius:15px;margin:0 7.5px 15px;background-position:center;background-size:contain;background-color:none;cursor:pointer}.category-card:first-child{margin-right:7.5px}.category-card:last-child{margin-left:7.5px}.category-card .background-image{height:100%;background-size:cover;background-position:center;overflow:hidden}.category-card .content{height:90px;padding:0 15px;display:flex;flex-direction:column;align-items:flex-start;position:absolute}.category-card .content .title{font-weight:bold;margin-bottom:0px;font-size:22px;color:white}.category-card .content .subtitle{font-weight:lighter;font-size:16px}

.featured-categories{display:flex;flex-direction:column;align-items:center;padding:20px 80px}

.sign-in-and-sign-up{width:850px;display:flex;justify-content:space-between;margin:30px auto}

.group{position:relative;margin:45px 0}.group .form-input{background:none;background-color:white;color:grey;font-size:18px;padding:10px 10px 10px 5px;display:block;width:100%;border:none;border-radius:0;border-bottom:1px solid grey;margin:25px 0;border-radius:5px;box-sizing:border-box}.group .form-input:focus{outline:none;box-shadow:0px 0px 10px 0px #8ceffe}.group .form-input:focus ~ .form-input-label{top:-20px;font-size:14px;color:#fff}.group input[type="password"]{letter-spacing:0.3em}.group .form-input-label{color:grey;font-size:16px;font-weight:normal;position:absolute;pointer-events:none;left:5px;top:10px;transition:300ms ease all}.group .form-input-label.shrink{top:-20px;font-size:14px;color:#fff}

.sign-in-wrapper{width:380px}.sign-in-wrapper input:last-child{margin-bottom:0px}.sign-in-wrapper button:first-child{margin-top:0px}

.sign-up-container{display:flex;flex-direction:column;width:380px}.sign-up-container .title{margin:10px 0}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  color: white;
}

.Particles {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: royalblue;
}



.moveImgContainer {
  width: 100%;
}

.moveImgContainer img {
  object-fit: contain;
  max-width: 100%;
  height: auto;
}

.background-img{
  background-image: url(https://firebasestorage.googleapis.com/v0/b/waterstone-98dcd.appspot.com/o/website_assets%2Fimages%2FWaterstone%20Marble%20Backdrop-min.jpg?alt=media&token=142926c8-262e-4faa-88cd-e0e5bb7e3b18);
  height: 100vh;
  background-size: contain;
  position: absolute;
  z-index: -1;
  background-attachment: fixed; 
}
